import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ShopProvider } from "contexts/ShopContext";

import "@shopify/polaris/build/esm/styles.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const search = window.location.search;
const queryParams = new URLSearchParams(search);
const host = queryParams.get("host");
const shop = queryParams.get("shop");

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ShopProvider shopHost={host} shopOrigin={shop}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ShopProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
