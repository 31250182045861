import React from "react";
import { ApolloProvider } from "@apollo/client";
import { useAppBridge } from "@shopify/app-bridge-react";
import { authenticatedFetch } from "@shopify/app-bridge-utils";
import Routes from "routes";
import makeApolloClient from "utils/apollo";

const ApolloApp = () => {
  const app = useAppBridge();
  const authFetch = authenticatedFetch(app);
  const client = makeApolloClient(authFetch);

  return (
    <ApolloProvider client={client}>
      <Routes />
    </ApolloProvider>
  );
};

export default ApolloApp;
