import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";

const makeApolloClient = (authenticatedFetch = null) => {
  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_BACKEND_API_URL,
    fetch: authenticatedFetch,
  });

  const client = new ApolloClient({
    link: httpLink,
    cache: new InMemoryCache({
      addTypename: false,
    }),
  });
  return client;
};

export default makeApolloClient;
