import React from "react";
import { Navigate, Route, Routes } from "react-router";
import { Loading } from "@shopify/app-bridge-react";

// const SetupGuide = React.lazy(() => import("views/SetupGuide/index"));
const ExperimentList = React.lazy(() => import("views/ExperimentList/index"));
const ExperimentForm = React.lazy(() => import("views/ExperimentForm/index"));
const Pricing = React.lazy(() => import("views/Pricing/index"));
const NotFound = React.lazy(() => import("views/Errors/NotFound/index"));
const InternalServerError = React.lazy(() => import("views/Errors/InternalServerError/index"));
const ExitIframe = React.lazy(() => import("views/ExitIframe/index"));

function AppRoutes() {
  return (
    <React.Suspense fallback={<Loading />}>
      <Routes>
        <Route path="/" element={<ExperimentList />} />

        <Route path="/exitiframe" element={<ExitIframe />} />

        <Route path="/tests" element={<ExperimentList />} />

        <Route path="/tests/new" element={<ExperimentForm />} />

        <Route path="/tests/:experimentId" element={<ExperimentForm />} />

        <Route path="/pricing" element={<Pricing />} />

        <Route path="/404" element={<NotFound />} />

        <Route path="/500" element={<InternalServerError />} />

        <Route path="/.netlify/functions/*" element={<Navigate to="/" />} />

        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
    </React.Suspense>
  );
}

export default AppRoutes;
