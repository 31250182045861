import React, { useCallback, useMemo } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { NavigationMenu, Provider as AppBridgeProvider } from "@shopify/app-bridge-react";
import { AppProvider } from "@shopify/polaris";
import translations from "@shopify/polaris/locales/en.json";
import ApolloApp from "components/ApolloApp";
import useShop from "hooks/useShop";

const App = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const history = useMemo(() => ({ replace: (path) => navigate(path, { replace: true }) }), [navigate]);
  const { shopHost, shopOrigin } = useShop();

  const config = {
    apiKey: process.env.REACT_APP_SHOPIFY_API_KEY,
    host: shopHost,
    shopOrigin: shopOrigin,
    forceRedirect: true,
  };

  const isOnboarding = useMemo(() => {
    return searchParams.has("onboard");
  }, [searchParams]);

  let navigationLinks = [
    {
      label: "Setup guide",
      destination: "/setup",
    },
    {
      label: "A/B Tests",
      destination: "/tests",
    },
    {
      label: "Pricing",
      destination: "/pricing",
    },
    {
      label: "Settings",
      destination: "/settings",
    },
  ];

  // Only show pricing link during onboarding
  if (isOnboarding) {
    navigationLinks = [
      {
        label: "Pricing",
        destination: "/pricing",
      },
    ];
  }

  const router = useMemo(
    () => ({
      location,
      history,
    }),
    [location, history]
  );

  const matcher = useCallback((link, location) => {
    return link.destination === location.pathname;
  }, []);

  return (
    <AppProvider i18n={translations}>
      <AppBridgeProvider config={config} router={router}>
        <NavigationMenu navigationLinks={navigationLinks} matcher={matcher} />
        <ApolloApp />
      </AppBridgeProvider>
    </AppProvider>
  );
};

export default App;
