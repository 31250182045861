import React, { createContext } from "react";

const initialContext = {
  shopHost: null,
  shopOrigin: null,
};

const ShopContext = createContext(initialContext);

export const ShopProvider = ({ children, shopHost, shopOrigin }) => {
  return (
    <ShopContext.Provider
      value={{
        shopHost,
        shopOrigin,
      }}
    >
      {children}
    </ShopContext.Provider>
  );
};

export default ShopContext;
